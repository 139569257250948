<template>    
  <section class="section-container">
    <v-row class="signin erpLogin">
      <v-col cols="12" sm="12" md="12" class="roundedWindow">
        <v-row class="">
          <v-col cols="12" class="mt-3 d-flex justify-center">
            <v-img
              max-width="180"
              src="/assets/logo-durand-branco.png"
            ></v-img>
          </v-col>
        </v-row>
        <h6 class="textColor mt-5 text-h6 text-center font-weight-bold">
          Bem vindo!
        </h6>
        <h5 class="textColor mb-10 mt-3 text-h5 text-center ">Marketplace</h5>
        <h3 class="textColor" >Área administrativa</h3>

        <v-form ref="loginForm">
          <v-text-field
            disabled
            clearable
            dark
            v-on:keydown.enter.prevent="doLogin"
            v-model="formData.email"
            label="Email"
            :rules="$store.state.formRules.emailRules"
          ></v-text-field>

          <v-text-field
            disabled
            dark
            v-on:keydown.enter.prevent="doLogin"
            :rules="$store.state.formRules.required"
            autocomplete="new-password"
            v-model="formData.password"
            label="Senha"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>

          <div class="text-center mt-3">
            <v-btn
              @click="doLogin"
              class="signin-btn blue--text text--darken-1"
              rounded
              block
              color="white"
              dark
            >
              Entrar
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </section>  
</template>

<script>
export default {
  components: {},
  data: () => ({
    dialog: false,
    formData: {
      email: "",
      password: null,
    },
    showPass: false,
  }),

  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },

  mounted() {
    //console.log(localStorage.getItem("userType"));
    // localStorage.setItem("userType", "admUser");
    // this.$store.commit("setUserType", "admUser");
  },
  methods: {
    doLogin() {
      // this.$store.commit("showLoader", {
      //   circle: true,
      // });
      // return;
      if (!this.$refs.loginForm.validate()) {
        return;
      }
      var obj = {
        url: "/api/grant-password",
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
      });
    },
    clear() {
      // you can use this method to clear login form
      this.formData.email = "";
      this.formData.password = null;
      this.$refs.observer.reset();
    },
  },
  props: {
    dialogLoginErp:Boolean,
    toggleDialogLoginErp:Function,
  }
};
</script>

<style>
.section-container .signin.erpLogin {
  min-height: 545px;
  max-width: 500px;
}
.roundedWindow {
  border-radius: 25px;
  background: #51524f;
  padding: 20px;
  width: 200px;
  height: 480px;
}
.textColor {
  color: azure;
}

</style>